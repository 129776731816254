import { Logo } from 'components/designSystem/SplashScreen/Logo'
import MambaLayoutIndex from 'components/layout/MambaLayout/MambaLayoutIndex'
import { indexPath } from 'components/paths'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { replace } from 'functions/router'
import { useRedesign } from 'hooks/useRedesign'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const ShellPage: FC = () => {
  const dispatch = useDispatch()
  const redesign = useRedesign()

  useEffect(() => {
    console.info('Redirect from shell', indexPath)
    dispatch(replace(indexPath))
  }, [dispatch])

  if (redesign) {
    return <Logo ready={false} />
  }

  return (
    <MambaLayoutIndex>
      <DefaultLoader />
    </MambaLayoutIndex>
  )
}

export default ShellPage
